import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import * as common from '@rushplay/common'
import * as emotion from '@emotion/core'
import styled from '@emotion/styled'

import { useGameInfoUrlQuery } from './use-game-info-url-query'

export function baseStyles(props) {
  // I'm writing them manually because you can't call functions inside css
  return emotion.css`
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    padding: 14px;
    font-weight: 400;
    background-color: ${props.theme.colors['blueberry']};
    color: white;
    border-radius: 9999px;
    margin: 8px;

    user-select: none;

    &:hover {
      color: ${props.theme.colors['base-gray']};
    }
    &:active,
    &.active {
      color: ${props.theme.colors['nav-item']};
      background-color: #1A0B41;
    }
  `
}

const DisabledMenuLink = styled.span`
  ${baseStyles};
  color: ${t.color('base-gray')};
  opacity: 0.85;
  cursor: not-allowed;

  &:hover,
  &:active,
  .active {
    color: ${t.color('base-gray')};
  }

  &.active {
    color: ${t.color('base-gray')};
  }
`

const MenuLink = styled(ReactRouter.NavLink)`
  ${baseStyles};
  color: white;
`

export function MenuItem(props) {
  const Icon = props.icon
  const gameInfoQuery = useGameInfoUrlQuery()

  if (props.disabled) {
    return (
      <DisabledMenuLink data-testid={props.testId}>
        <common.Box fontSize={5} color="inherit">
          <Icon />
        </common.Box>
        <common.Box fontSize={3} display="inline-flex" fontFamily="head">
          {props.children}
        </common.Box>
      </DisabledMenuLink>
    )
  }
  return (
    <MenuLink
      data-testid={props.testId}
      to={props.to}
      isActive={match => {
        if (gameInfoQuery.referrer) {
          return (
            R.equals(gameInfoQuery.referrerPath, props.to) ||
            R.equals(`/${gameInfoQuery.referrer}`, props.to)
          )
        }
        // We don't want loyalty-program to be active
        if (R.test(/loyalty-program=cashback/, props.to)) {
          return
        }

        return match
      }}
      exact={props.exact}
      onClick={props.onClick}
    >
      <common.Box fontSize={5} color="inherit">
        <Icon />
      </common.Box>
      <common.Box
        fontSize={3}
        paddingLeft={1}
        display="inline-flex"
        fontFamily="head"
        flex="1"
      >
        {props.children}
      </common.Box>
    </MenuLink>
  )
}

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  exact: PropTypes.bool,
  icon: PropTypes.func.isRequired,
  testId: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}
