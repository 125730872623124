import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as FramerMotion from 'framer-motion'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as T from '@rushplay/theme'
import styled from '@emotion/styled'

import * as CombinedSelectors from './combined-selectors'
import * as Icons from './icons'
import * as Inventory from './inventory'
import { Badge } from './badge'
import { baseStyles } from './menu-item'
import { useSafeUpdateQuery } from './use-safe-update-query'

const DisabledLink = styled.span`
  ${baseStyles};
  color: ${T.color('disabled')};
  opacity: 0.85;
  cursor: not-allowed;

  &:hover,
  &:active,
  .active {
    color: #c2c2c2;
  }

  &.active {
    color: #c2c2c2;
  }
`
const MenuLink = styled(ReactRouter.Link)`
  ${baseStyles};
`

const variants = {
  active: {
    rotate: [0, 20, -20, 20, -20, 0],
    transition: {
      duration: 1,
      loop: Number.POSITIVE_INFINITY,
      repeatDelay: 6,
    },
  },
  inactive: {
    rotate: 0,
  },
}

export function PromotionsMenuItem(props) {
  const shouldAnimate = ReactRedux.useSelector(
    CombinedSelectors.hasPromotionNotifications
  )
  const hasUnseenInventory = ReactRedux.useSelector(state =>
    Inventory.hasUnseenItems(state.inventory)
  )
  const unseenInventoryCount = ReactRedux.useSelector(state =>
    Inventory.getUnseenItemsCount(state.inventory)
  )

  const promotionsQuery = useSafeUpdateQuery({
    promotions: hasUnseenInventory ? 'inventory' : 'campaigns',
  })

  if (props.disabled) {
    return (
      <DisabledLink data-testid={props.testId}>
        <Common.Box fontSize={5} color="inherit">
          <Icons.PromotionsMenu />
        </Common.Box>

        <Common.Box
          fontFamily="head"
          fontSize={3}
          paddingLeft={1}
          display="inline-flex"
        >
          {props.children}
        </Common.Box>
      </DisabledLink>
    )
  }

  return (
    <MenuLink
      data-testid={props.testId}
      to={`?${promotionsQuery}`}
      onClick={props.onClick}
    >
      <FramerMotion.motion.div
        variants={variants}
        animate={shouldAnimate ? 'active' : 'inactive'}
      >
        <Common.Box fontSize={5} color="inherit">
          <Icons.PromotionsMenu />
        </Common.Box>
      </FramerMotion.motion.div>
      <Common.Box
        fontFamily="head"
        fontSize={3}
        paddingLeft={1}
        display="inline-flex"
      >
        {props.children}
      </Common.Box>
      {hasUnseenInventory && <Badge>{unseenInventoryCount}</Badge>}
    </MenuLink>
  )
}

PromotionsMenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  onClick: PropTypes.func,
}

export default PromotionsMenuItem
