import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as T from '@rushplay/theme'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import * as Icons from './icons'
import * as Player from './player'
import { MenuItem } from './menu-item'

const Notification = styled('div', {
  shouldForwardProp: Common.noneOf(['hasNotifications']),
})`
  position: relative;

  &:after {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 0.5em;
    height: 0.5em;
    border-radius: 1em;
    background-color: ${T.color('nav-badge')};
    content: '';
    transform: ${props => (props.hasNotifications ? 'scale(1)' : 'scale(0)')};
    transition: transform 0.5s cubic-bezier(0.36, 1.78, 0.55, 0.68);
  }
`

export function MyAccountMenuItem(props) {
  const { authenticated } = Herz.Auth.useSession()

  const hasSeenAllAccountDocuments = ReactRedux.useSelector(state =>
    Player.hasSeenAllAccountDocuments(state.player)
  )

  return (
    <MenuItem
      disabled={props.disabled}
      icon={Icons.Person}
      testId={props.testId}
      to="/account"
    >
      <Notification
        hasNotifications={authenticated && !hasSeenAllAccountDocuments}
      >
        <Common.Box
          fontSize={3}
          paddingLeft={authenticated ? '0px' : 1}
          display="inline-flex"
          fontFamily="head"
          flex="1"
        >
          {props.children}
        </Common.Box>
      </Notification>
    </MenuItem>
  )
}

MyAccountMenuItem.propTypes = {
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  children: PropTypes.string.isRequired,
}

export default MyAccountMenuItem
